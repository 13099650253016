<div class="nav-menu__container" [class.nav-menu__container_transitions]="canAnimate"
  [class.nav-menu__container_animate]="isFullPanel">

  <div class="nav-menu__toggle-container">
    <button mat-icon-button class="nav-menu__toggle" (click)="!isNotDesktop ? close() : isFullPanel = !isFullPanel">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="nav-menu__logo-container">
    <img src="../../../assets/svg/logo.svg" width="100"/>
  </div>

  <div class="nav-menu__links">
    <a mat-flat-button class="nav-menu__link" routerLink="/dashboard" routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">dashboard</mat-icon>
      <span class="nav-menu__link-text">Рабочий стол</span>
    </a>
    <a mat-flat-button class="nav-menu__link" routerLink="/lots" router routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">list_alt</mat-icon>
      <span class="nav-menu__link-text">Список лотов</span>
    </a>
    <a *ngIf="api.user" mat-flat-button class="nav-menu__link" routerLink="/profile"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">domain</mat-icon>
      <span class="nav-menu__link-text">
        {{api.getAccess('subscriptionsectionproducts') ? 'Реквизиты' : 'Мои данные'}}
      </span>
    </a>
    <a *ngIf="api.getAccess('subscriptionsectionproducts')" mat-flat-button class="nav-menu__link"
      routerLink="/subscriptionsectionproducts" routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">bookmarks</mat-icon>
      <span class="nav-menu__link-text">Подписки</span>
    </a>
    <a *ngIf="api.getAccess('messages')" mat-flat-button class="nav-menu__link" routerLink="/messages/messages"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">notifications</mat-icon>
      <span class="nav-menu__link-text">Уведомления</span>
    </a>
    <a *ngIf="api.getAccess('lottemplates')" mat-flat-button class="nav-menu__link" routerLink="/lottemplates"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">queue</mat-icon>
      <span class="nav-menu__link-text">Конфигуратор</span>
    </a>
    <a *ngIf="api.getAccess('listusersections')" mat-flat-button class="nav-menu__link" routerLink="/users"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">group</mat-icon>
      <span class="nav-menu__link-text">Пользователи</span>
    </a>
    <a *ngIf="api.getAccess('listhandbooks')" mat-flat-button class="nav-menu__link" routerLink="/handbooks"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">library_books</mat-icon>
      <span class="nav-menu__link-text">Справочники</span>
    </a>
    <a *ngIf="api.getAccess('reports')" mat-flat-button class="nav-menu__link" routerLink="/reports"
       routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">assignment</mat-icon>
      <span class="nav-menu__link-text">Отчеты</span>
    </a>
    <!-- <a *ngIf="api.getAccess('sharedhistory')" mat-flat-button class="nav-menu__link" (click)="sharedHistoryClick()"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">history</mat-icon>
      <span class="nav-menu__link-text">История изменений</span>
    </a> -->
    <a *ngIf="api.getAccess('sharedhistory')" mat-flat-button class="nav-menu__link" routerLink="/history"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">history</mat-icon>
      <span class="nav-menu__link-text">История изменений</span>
    </a>
    <div class="nav-menu__links-separator"></div>
    <a *ngIf="api.getAccess('settings')" mat-flat-button class="nav-menu__link" routerLink="/settings"
      routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">settings</mat-icon>
      <span class="nav-menu__link-text">Настройки</span>
    </a>
    <a *ngIf="(!api.user || api.getAccess('subscriptionsectionproducts'))" mat-flat-button class="nav-menu__link"
      routerLink="/instruction" routerLinkActive="nav-menu__link_active">
      <mat-icon class="nav-menu__link-icon">help_outline</mat-icon>
      <span class="nav-menu__link-text">Помощь</span>
    </a>
    <ng-container *ngIf="false && devService.isDevMode">
      <button mat-flat-button color="warn" routerLink="/design-system">Design system</button>
      <button mat-flat-button color="warn" (click)="devService.socketsToggle()">{{devService.isSocketsOn ? 'Отключить' :
        'Включить'}} сокеты</button>
    </ng-container>

  </div>

  <div class="nav-menu__info" *ngIf="(!api.user || api.getAccess('subscriptionsectionproducts'))">
    <div class="nav-menu__info-company">Техподдержка</div>
    <div class="nav-menu__info-text" *ngIf="settings">
      <a class="link" [href]="'tel:'+settings.phone">{{settings.phone}}</a><br>
      <a class="link" [href]="'mailto:'+settings.email">{{settings.email}}</a>
    </div>
    <div style="margin-bottom: 20px"></div>
    <div class="nav-menu__info-company">ООО «Птицефабрика Акашевская»</div>
    <div class="nav-menu__info-text">Настоящий электронный запрос ценовых предложений, проводимый на сайте ООО «Птицефабрика Акашевская» не является торгами, тендером или публичным конкурсом в понимании ст.447-449, 1057-1061 ГК РФ.</div>
  </div>

</div>
